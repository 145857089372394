import * as React from "react";
import { Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import TabPanel from "../TabPanel";
import { useTranslation } from "react-i18next";
import Accordions from "../Accordions";
import { FaqTab, FaqTitle } from "./style";
import faqs from "../../Constants/faqs";

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const VerticalTabs = (props) => {
  const { t } = useTranslation();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const results = [...faqs.generalFaqs].filter((item) => {
    if (!props.search || props.search.trim().lenght == 0) {
      return true;
    }
    const summary = t(item.summary).toLowerCase();
    const index = summary.indexOf(props.search.toLowerCase());
    if (index >= 0) {
      return true;
    }
  });

  return (
    <Grid container columns={{ xs: 1, sm: 6, md: 12 }}>
      <Grid item xs={1} sm={4} md={4}>
        <FaqTitle>{t("faq.tabs.title")}</FaqTitle>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Faq Tabs"
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <FaqTab label={t("faq.tabs.tab1")} {...a11yProps(0)} />
          {/* <FaqTab label={t("faq.tabs.tab2")} {...a11yProps(1)} />
          <FaqTab label={t("faq.tabs.tab3")} {...a11yProps(2)} />*/}
        </Tabs>
      </Grid>
      <Grid xs={1} sm={8} md={8}>
        <TabPanel value={value} index={0}>
          <Accordions accordions={results} />
        </TabPanel>
        {/* <TabPanel value={value} index={1}>
          <Accordions accordions={faqs.marketAndBusinessFaqs} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Accordions accordions={faqs.productAndServicesFaqs} />
        </TabPanel>*/}
      </Grid>
    </Grid>
  );
};

export default VerticalTabs;
